import React, { useState } from 'react';
import styles from './jsExamDetailsPage.module.css';

const ExamDetailsPage = () => {
    const [showCertificationDetails, setShowCertificationDetails] = useState(false);

    const handleLearnMoreClick = () => {
        setShowCertificationDetails(!showCertificationDetails);
    };
    
    return (
        <div className={styles.examContainer}>
            <h1>JavaScript Professional Developer Exam Outline</h1>
            <section className="about-exam">
                <h2>About the JavaScript Professional Developer Exam</h2>
                <p>
                    The JavaScript Professional Developer Exam is designed for individuals seeking to solidify their foundational understanding of JavaScript, a critical skill for web development and software engineering. This exam targets those who are either new to JavaScript or looking to formalize their knowledge and expertise in this versatile programming language. Achieving certification in JavaScript Essentials gives employers confidence in your ability to write, analyze, and understand JavaScript code, enhancing your potential for roles in web development, application development, and other related fields.
                </p>
            </section>
            
            <section className="topics-covered">
                <h2>Topics Covered in the JavaScript Professional Developer Exam</h2>
                <ul className="no-bullets">
                    <li>Basic Structure of Programs
                        <ul>
                            <li>Understanding how JavaScript programs are structured</li>
                            <li>Familiarity with syntax, comments, and code organization</li>
                        </ul>
                    </li>
                    <li>Data Types
                        <ul>
                            <li>Mastery of the eight primary data types in JavaScript: number, string, boolean, null, undefined, object, symbol, and bigint</li>
                            <li>Practical application and manipulation of these data types</li>
                        </ul>
                    </li>
                    <li>Operators
                        <ul>
                            <li>Utilization of arithmetic operators for mathematical calculations</li>
                            <li>Application of comparison operators to compare values</li>
                            <li>Usage of logical operators to perform logical operations</li>
                            <li>Implementation of ternary operators for concise conditional expressions</li>
                        </ul>
                    </li>
                    <li>Variable Declaration
                        <ul>
                            <li>Proper use of `var`, `let`, and `const` for declaring variables</li>
                            <li>Understanding the scope and hoisting behavior associated with each declaration type</li>
                        </ul>
                    </li>
                    <li>Functions
                        <ul>
                            <li>Creation and use of regular and arrow functions</li>
                            <li>Understanding the differences and appropriate contexts for each type of function</li>
                        </ul>
                    </li>
                    <li>Special Values: Null and Undefined
                        <ul>
                            <li>Differentiation between null and undefined values</li>
                            <li>Recognizing how and when these values are used in JavaScript</li>
                        </ul>
                    </li>
                </ul>
            </section>

            <section className="exam-info">
                <h2>JavaScript Professional Developer Examination Information</h2>
                <table>
                    <tbody>
                        <tr>
                            <td>Length of exam</td>
                            <td>60 minutes</td>
                        </tr>
                        <tr>
                            <td>Number of items</td>
                            <td>90</td>
                        </tr>
                        <tr>
                            <td>Item format</td>
                            <td>Multiple choice</td>
                        </tr>
                        <tr>
                            <td>Passing Score</td>
                            <td>73%</td>
                        </tr>
                        <tr>
                            <td>Exam language availability</td>
                            <td>English</td>
                        </tr>
                        <tr>
                            <td>Testing options</td>
                            <td>Online Testing</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            <section className="exam-weights">
                <h2>JavaScript Professional Developer Examination Weights</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Domain</th>
                            <th>Task</th>
                            <th>Recommended % of test by Domain</th>
                            <th>Recommended # of questions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Syntax</td>
                            <td>
                                <ul>
                                    <li>Identify the basic structure of JavaScript programs.</li>
                                    <li>Utilize the eight types in JavaScript code.</li>
                                    <li>Apply arithmetic, comparison, logical, and ternary operators correctly.</li>
                                    <li>Declare variables (var, let, const).</li>
                                    <li>Use arrow functions.</li>
                                    <li>Identify null and undefined properties.</li>
                                </ul>
                            </td>
                            <td>22.94%</td>
                            <td>29</td>
                        </tr>
                        <tr>
                            <td>Functions</td>
                            <td>
                                <ul>
                                    <li>Define functions using function declarations.</li>
                                    <li>Execute functions and analyze returned data.</li>
                                    <li>Implement default parameters in functions.</li>
                                    <li>Create and use callback functions.</li>
                                </ul>
                            </td>
                            <td>7.82%</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>Controls</td>
                            <td>
                                <ul>
                                    <li>Implement conditional statements (if, else if, else) to control program flow.</li>
                                    <li>Utilize switch statements for handling multiple conditions efficiently.</li>
                                    <li>Differentiate between boolean values and their impact on control flow.</li>
                                    <li>Check for null and undefined properties to alter program execution.</li>
                                </ul>
                            </td>
                            <td>7.83%</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>Scope</td>
                            <td>
                                <ul>
                                    <li>Recognize JavaScript scopes (global scope, function scope, block scope).</li>
                                    <li>Apply good scoping practices to ensure code clarity and maintainability.</li>
                                </ul>
                            </td>
                            <td>3.91%</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>Arrays</td>
                            <td>
                                <ul>
                                    <li>Create and modify arrays.</li>
                                    <li>Access and modify array elements.</li>
                                    <li>Update array elements and combine arrays.</li>
                                    <li>Calculate array length and utilize it in array operations.</li>
                                    <li>Build and parse nested arrays.</li>
                                </ul>
                            </td>
                            <td>10.27%</td>
                            <td>13</td>
                        </tr>
                        <tr>
                            <td>Loops</td>
                            <td>
                                <ul>
                                    <li>Implement for, while, and do...while loops to iterate over data structures.</li>
                                    <li>Use break and continue statements to control loop execution.</li>
                                    <li>Apply nested loops for complex iterations and data manipulation.</li>
                                </ul>
                            </td>
                            <td>5.49%</td>
                            <td>7</td>
                        </tr>
                        <tr>
                            <td>Iterators</td>
                            <td>
                                <ul>
                                    <li>Use iterators (e.g., forEach, map, filter, etc.) for array manipulation and processing.</li>
                                    <li>Apply higher-order functions to work with arrays and collections efficiently.</li>
                                </ul>
                            </td>
                            <td>3.13%</td>
                            <td>4</td>
                        </tr>
                        <tr>
                            <td>Objects</td>
                            <td>
                                <ul>
                                    <li>Create object literals and define properties using key-value pairs.</li>
                                    <li>Access object properties using dot notation and bracket notation.</li>
                                    <li>Access and iterate through nested object properties.</li>
                                    <li>Identify undefined objects.</li>
                                    <li>Use the spread operator and destructuring.</li>
                                </ul>
                            </td>
                            <td>9.61%</td>
                            <td>12</td>
                        </tr>
                    </tbody>
                </table>
            </section>

            {/* <button onClick={handleLearnMoreClick} className={styles.learnMoreButton}>
                Learn More
            </button>

            {showCertificationDetails && (
                <section className="certification-details">
                    <h2>HTML/CSS Designer Certification</h2>
                    <h3>Description of Role</h3>
                    <p>Open to all individuals preparing to enter or continue in the Information Technology field as a web designer or app developer.</p>
                    <h3>Certification Eligibility Requirements</h3>
                    <p>None: anyone can register to take the HTML/CSS certification.</p>
                    <h3>Recommended</h3>
                    <p>Familiar with the use of a desktop or laptop computer and the Internet. Use Windows or Mac operating systems.</p>
                    <h3>Initial Certification Requirements</h3>
                    <p>Submit the HTML/CSS certification application.</p>
                    <ul>
                        <li>Agree to the rules of certification (maintenance of security, confidentiality, and reporting of any fraudulent or inappropriate activity related to the certification examination or the certification credential).</li>
                    </ul>
                    <h3>Maintenance Requirements</h3>
                    <p>Agree to comply with certification rules (security, confidentiality, etc.)</p>
                    <h3>Certification Term</h3>
                    <p>3 years</p>
                    <h3>Recertification Requirements</h3>
                    <p>Objective: To ensure continued proficiency and updated knowledge in frameworks that support HTML and CSS.</p>
                    <ul>
                        <li>Agree to comply with certification rules (security, confidentiality, etc.)</li>
                        <li>Submit evidence of 20 units of continuing education or pass the current version of the examination</li>
                    </ul>
                    <h3>Certification Test Content Outline</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Domain</th>
                                <th>Task</th>
                                <th>Current % of Test</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>HTML (Hyper Text Markup Language)</td>
                                <td>
                                    <ul>
                                        <li>Structure and Semantics</li>
                                        <li>Understanding the basic structure of an HTML document (`&lt;!DOCTYPE&gt;`, `&lt;html&gt;`, `&lt;head&gt;`, `&lt;body&gt;`)</li>
                                        <li>Identify and utilize semantic HTML5 elements (`&lt;header&gt;`, `&lt;nav&gt;`, `&lt;section&gt;`, `&lt;article&gt;`, `&lt;footer&gt;`, etc.) and when to use them</li>
                                    </ul>
                                </td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>Basic Tags</td>
                                <td>
                                    <ul>
                                        <li>Implement essential tags like `&lt;div&gt;`, `&lt;p&gt;`, `&lt;h1&gt;` to `&lt;h6&gt;`, `&lt;a&gt;` (anchor tags), `&lt;img&gt;`, `&lt;ul&gt;` (unordered lists), `&lt;ol&gt;` (ordered lists), `&lt;li&gt;` (list items), `&lt;span&gt;`, `&lt;table&gt;`, `&lt;form&gt;`, `&lt;input&gt;`, etc.</li>
                                        <li>Determine use for class versus functional components</li>
                                        <li>Utilize nesting of components</li>
                                    </ul>
                                </td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>Attributes</td>
                                <td>Apply common attributes such as id, class, src, href, alt, style, title, placeholder, value, type, etc.</td>
                                <td>7%</td>
                            </tr>
                            <tr>
                                <td>Forms</td>
                                <td>
                                    <ul>
                                        <li>Create forms using `&lt;form&gt;`, `&lt;input&gt;`, `&lt;textarea&gt;`, `&lt;select&gt;`, `&lt;button&gt;`, etc.</li>
                                        <li>Explain and demonstrate form submission processes (method and action attributes)</li>
                                    </ul>
                                </td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>Links and Images</td>
                                <td>
                                    <ul>
                                        <li>Link to other pages or resources using `&lt;a&gt;` tags</li>
                                        <li>Embed images with `&lt;img&gt;` tags and handle alternative text (alt attribute)</li>
                                    </ul>
                                </td>
                                <td>8%</td>
                            </tr>
                            <tr>
                                <td>Tables</td>
                                <td>Structure tabular data using `&lt;table&gt;`, `&lt;tr&gt;`, `&lt;td&gt;`, `&lt;th&gt;`, `&lt;thead&gt;`, `&lt;tbody&gt;`, `&lt;tfoot&gt;`</td>
                                <td>7%</td>
                            </tr>
                            <tr>
                                <td>Media</td>
                                <td>Embed multimedia content like audio and video (`&lt;audio&gt;`, `&lt;video&gt;`) with fallback options</td>
                                <td>8%</td>
                            </tr>
                            <tr>
                                <td>CSS (Cascading Style Sheets)</td>
                                <td>
                                    <ul>
                                        <li>Selectors and Declarations</li>
                                        <li>Identify and apply CSS selectors (element, class, ID, attribute selectors) and declaration blocks</li>
                                        <li>Style elements using properties like color, font-size, margin, padding, background, border, etc.</li>
                                    </ul>
                                </td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>Box Model</td>
                                <td>
                                    <ul>
                                        <li>Explain the concept of the box model (content, padding, border, margin)</li>
                                        <li>Adjust box model properties (width, height, box-sizing, etc.)</li>
                                    </ul>
                                </td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>Layout</td>
                                <td>
                                    <ul>
                                        <li>Create basic layouts using CSS (floats, positioning, flexbox, grid)</li>
                                        <li>Implement responsive design principles (media queries, fluid layouts)</li>
                                    </ul>
                                </td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>Typography</td>
                                <td>
                                    <ul>
                                        <li>Style text with properties like font-family, font-size, font-weight, text-align, line-height, etc.</li>
                                    </ul>
                                </td>
                                <td>2%</td>
                            </tr>
                            <tr>
                                <td>Backgrounds and Borders</td>
                                <td>
                                    <ul>
                                        <li>Apply background styles (background-color, background-image, background-size, etc.)</li>
                                        <li>Style borders (border-width, border-style, border-color, border-radius)</li>
                                    </ul>
                                </td>
                                <td>2%</td>
                            </tr>
                            <tr>
                                <td>Transforms and Transitions</td>
                                <td>
                                    <ul>
                                        <li>Add interactivity with CSS transitions (`transition`) and animations (`@keyframes`)</li>
                                        <li>Transform elements (`transform`, `rotate`, `scale`, `translate`)</li>
                                    </ul>
                                </td>
                                <td>3%</td>
                            </tr>
                            <tr>
                                <td>Flexbox and Grid</td>
                                <td>Utilize CSS layout systems like Flexbox and Grid for advanced layouts</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>Responsive Design</td>
                                <td>Design websites that adapt to different screen sizes and devices using media queries</td>
                                <td>5%</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            )} */}
        </div>
    );
};

export default ExamDetailsPage;
