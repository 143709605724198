import React from "react";
import "./policiesPage.css";
import { Link } from "react-router-dom";

const CertificateIssuanceAndUsePolicy = () => {
  return (
    <div className="container">
      <Link to="/policies-page" className="back-button">
        Back to Policies
      </Link>
      <h1 className="title">Certification Issuance and Use Policy</h1>

      <ol>
        <li>
          <h2 className="sub-title">Purpose</h2>
          <p className="paragraph">
            Safeguarding user data, especially address information, is paramount
            in the process of Certification issuance and use. Address
            information is a key component of Personally Identifiable
            Information (PII), which, if compromised, can lead to serious
            consequences such as identity theft, fraud, and invasion of privacy.
            By ensuring the confidentiality and security of address information,
            organizations demonstrate their commitment to protecting the
            personal data of their candidates and stakeholders. This not only
            fosters trust and confidence in the certification process but also
            mitigates the risk of legal and regulatory non-compliance.
            Additionally, safeguarding address information reinforces the
            organization's reputation for integrity, reliability, and ethical
            conduct, which are essential for maintaining credibility in the eyes
            of candidates, partners, and the broader community. Overall, the
            importance of safeguarding user data on Certification issuance and
            use cannot be overstated, as it safeguards individuals' privacy
            rights and preserves the integrity of the certification process.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Online Certification Issuance</h2>
          <p className="paragraph">
            Certifications will be issued primarily through an online platform
            upon successful completion of the assessment. The online issuance
            process ensures timely delivery and accessibility for candidates.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Passing Score Requirement</h2>
          <p className="paragraph">
          Certifications will only be issued to candidates who achieve the minimum passing score of each exam assessment (see <Link to="/credentials">here</Link> for passing score for each exam). These threshold ensures that recipients have demonstrated proficiency in the subject matter.
          </p>
        </li>
        <li>
          <h2 className="sub-title">
            Protection of Personally Identifiable Information (PII)
          </h2>
          <p className="paragraph">
            All candidates' PII collected during the certification process will
            be safeguarded and protected in accordance with the Privacy Act of
            1974. This includes but is not limited to names, addresses, email
            addresses, and any other identifying information.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Internal Information Sharing</h2>
          <p className="paragraph">
            Information pertaining to candidates' performance, including ID
            numbers and voucher numbers, will be shared internally only on a
            need-to-know basis. This ensures that sensitive information is
            handled with discretion and confidentiality.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Tie-in of ID and Voucher Numbers</h2>
          <p className="paragraph">
            ID numbers and voucher numbers will be securely tied to each
            candidate's personal information to prevent unauthorized access and
            misuse. This linkage enhances the security of the certification
            process and mitigates the risk of fraudulent activities.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Compliance</h2>
          <p className="paragraph">
            All personnel involved in the issuance and use of Certifications must
            adhere to this policy and any associated procedures. Non-compliance
            may result in disciplinary action, up to and including termination
            of employment or legal consequences.
          </p>
        </li>
        <li>
          <h2 className="sub-title">Review and Updates</h2>
          <p className="paragraph">
            This policy will be reviewed periodically to ensure alignment with
            evolving regulations and best practices. Updates will be made as
            necessary to maintain the integrity and effectiveness of the
            certification process. By adhering to this policy, we uphold the
            integrity of our certification program and prioritize the security
            and privacy of candidates' information.
          </p>
        </li>
      </ol>
    </div>
  );
};

export default CertificateIssuanceAndUsePolicy;
