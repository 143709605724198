import React, { useState } from "react";
import styles from "./htmlCSSExamDetailsPage.module.css";

const HtmlCssExamDetailsPage = () => {
  const [showCertificationDetails, setShowCertificationDetails] =
    useState(false);

  const handleLearnMoreClick = () => {
    setShowCertificationDetails(!showCertificationDetails);
  };

  return (
    <div className={styles.examContainer}>
      <h1>HTML and CSS Web Designer Exam Outline</h1>
      <section className="about-exam">
        <h2>About the HTML and CSS Web Designer Exam</h2>
        <p>
          The HTML and CSS Web Designer Exam is designed for individuals looking
          to establish a solid foundation in web development. This exam covers
          essential topics related to HTML and CSS, which are the building
          blocks of web design. Achieving certification in HTML and CSS
          Essentials demonstrates your ability to create, style, and structure
          web pages effectively, making you a valuable candidate for web
          development roles.
        </p>
      </section>

      <section className="topics-covered">
        <h2>Topics Covered in the HTML and CSS Web Designer Exam</h2>
        <ul className="no-bullets">
          <li>
            Structure and Semantics
            <ul>
              <li>Understanding the basic structure of an HTML document.</li>
              <li>
                Identify and utilize semantic HTML5 elements and when to use
                them.
              </li>
            </ul>
          </li>
          <li>
            Basic Tags
            <ul>
              <li>
                Using tags for text formatting, such as `p`, `h1`-`h6`,
                `strong`, `em`, etc.
              </li>
              <li>Implement essential tags.</li>
            </ul>
          </li>
          <li>
            Attributes
            <ul>
              <li>
                Apply common attributes such as id, class, src, href, alt,
                style, title, placeholder, value, type, etc.
              </li>
            </ul>
          </li>
          <li>
            Forms
            <ul>
              <li>
                Create forms using `form`, `input`, `textarea`, `select`,
                `button`, etc.
              </li>
              <li>
                Explain and demonstrate form submission processes (method and
                action attributes).
              </li>
            </ul>
          </li>
          <li>
            Links and Images
            <ul>
              <li>Link to other pages or resources using `a` tags.</li>
              <li>
                Embed images with `img` tags and handle alternative text (alt
                attribute).
              </li>
            </ul>
          </li>
          <li>
            Tables
            <ul>
              <li>Learn to structure tabular data.</li>
            </ul>
          </li>
          <li>
            Media
            <ul>
              <li>
                Embed multimedia content like audio and video (`audio`, `video`)
                with fallback options.
              </li>
            </ul>
          </li>
          <li>
            Selectors and Declarations
            <ul>
              <li>
                Identify and apply CSS selectors (element, class, ID, attribute
                selectors) and declaration blocks.
              </li>
              <li>
                Style elements using properties like color, font-size, margin,
                padding, background, border, etc.
              </li>
            </ul>
          </li>
          <li>
            Box Model
            <ul>
              <li>
                Explain the concept of the box model (content, padding, border,
                margin).
              </li>
              <li>
                Adjust box model properties (width, height, box-sizing, etc.).
              </li>
            </ul>
          </li>
          <li>
            Layout
            <ul>
              <li>
                Create basic layouts using CSS (floats, positioning, flexbox,
                grid).
              </li>
              <li>
                Implement responsive design principles (media queries, fluid
                layouts).
              </li>
            </ul>
          </li>
          <li>
            Typography
            <ul>
              <li>
                Style text with properties like font-family, font-size,
                font-weight, text-align, line-height, etc.
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section className="exam-info">
        <h2>HTML and CSS Web Designer Exam Information</h2>
        <table>
          <tbody>
            <tr>
              <td>Length of exam</td>
              <td>60 minutes</td>
            </tr>
            <tr>
              <td>Number of items</td>
              <td>90</td>
            </tr>
            <tr>
              <td>Item format</td>
              <td>Multiple choice</td>
            </tr>
            <tr>
              <td>Passing Score</td>
              <td>75%</td>
            </tr>
            <tr>
              <td>Exam language availability</td>
              <td>English</td>
            </tr>
            <tr>
              <td>Testing options</td>
              <td>Online Testing</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section className="exam-weights">
        <h2>HTML and CSS Web Designer Exam Weights</h2>
        <table>
          <thead>
            <tr>
              <th>Domain</th>
              <th>Task</th>
              <th>Recommended % of test by Domain</th>
              <th>Recommended # of questions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>HTML Structure</td>
              <td>
                <ul>
                  <li>Identify the basic structure of an HTML document.</li>
                  <li>Use essential HTML tags for content organization.</li>
                  <li>Create hyperlinks and embed images.</li>
                </ul>
              </td>
              <td>20%</td>
              <td>18</td>
            </tr>
            <tr>
              <td>CSS Basics</td>
              <td>
                <ul>
                  <li>Apply basic CSS styling to HTML elements.</li>
                  <li>Utilize classes and IDs to target specific elements.</li>
                  <li>Implement colors, fonts, and text alignment.</li>
                </ul>
              </td>
              <td>25%</td>
              <td>23</td>
            </tr>
            <tr>
              <td>Layout Techniques</td>
              <td>
                <ul>
                  <li>Use the box model to design layouts.</li>
                  <li>Apply flexbox and grid systems for responsive design.</li>
                </ul>
              </td>
              <td>20%</td>
              <td>18</td>
            </tr>
            <tr>
              <td>Responsive Design</td>
              <td>
                <ul>
                  <li>Create responsive layouts with media queries.</li>
                  <li>
                    Design fluid layouts that adapt to different screen sizes.
                  </li>
                </ul>
              </td>
              <td>20%</td>
              <td>17</td>
            </tr>
            <tr>
              <td>HTML Forms</td>
              <td>
                <ul>
                  <li>Create and style HTML forms.</li>
                  <li>Implement form validation techniques.</li>
                  <li>Understand the use of different input types.</li>
                </ul>
              </td>
              <td>10%</td>
              <td>9</td>
            </tr>
            <tr>
              <td>Advanced CSS</td>
              <td>
                <ul>
                  <li>Use CSS animations and transitions.</li>
                  <li>Implement CSS preprocessors like SASS.</li>
                  <li>Utilize CSS variables for theming.</li>
                </ul>
              </td>
              <td>5%</td>
              <td>5</td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default HtmlCssExamDetailsPage;
